<template>
  <div
    v-if="getUser.role.slug === 'responsable-relation-client' ||
      getUser.role.slug === 'super-administrateur'
    "
  >
    <div v-if="this.$route.query.packageId !== 'null'">
      <b-breadcrumb class="mb-2">
        <b-breadcrumb-item to="/orders/business">
          Commandes
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          Nouvelle demande
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <div v-else>
      <b-breadcrumb class="mb-2">
        <b-breadcrumb-item to="/orders/business">
          Packages
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          Nouvelle demande
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <new-business-client :is-add-new-business-user-sidebar-active.sync="isAddNewBusinessUserSidebarActive" />
    <b-card>
      <validation-observer
        #default="{}"
        ref="addNewOrder"
        tag="form"
      >
        <!-- body -->

        <b-form>
          <!-- Client -->
          <b-row>
            <b-col
              v-if="$route.query.client_hide == 'false'"
              cols="12"
              md="10"
              lg="8"
              sm="6"
              class=""
            >
              <b-row>
                <b-col
                  cols="12"
                  class="pr-1"
                >
                  <b-form-group
                    v-if="getCustomer !== null"
                    label="Client"
                    label-for="Client"
                  >
                    <b-form-input
                      id="customer"
                      v-model="clientName"
                      type="text"
                      readonly
                    />
                  </b-form-group>
                  <validation-provider
                    v-else
                    #default="{ errors }"
                    name="Client"
                    rules="required"
                  >
                    <b-form-group
                      label="Client"
                      label-for="Client"
                    >
                      <v-select
                        id="customer"
                        v-model="payload.user_id"
                        :reduce="(customer) => customer.id"
                        label="name"
                        :options="customerOptions"
                        :state="errors.length > 0 ? false : null"
                      >
                        <template v-slot:no-options>
                          <b-spinner
                            v-if="isCustomersWithoutPaginationLoading"
                            style="width: 2.5rem; height: 2.5rem"
                            class="align-middle text-info"
                          />
                          <div>
                            <span class="mt-1 font-medium-1">Aucun client ne correspond à ces détails</span>
                            <li
                              class="text-success mt-1 mb-1 font-medium-1"
                              style="text-align: center; cursor: pointer"
                              @click="isAddNewBusinessUserSidebarActive = true"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="text-center align-middle ml-25">Inscrire le client</span>
                            </li>
                          </div>
                        </template>
                      </v-select>
                      <small class="text-danger"> {{ errors[0] }} </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              md="4"
              lg="4"
              sm="6"
              class="d-flex align-items-center justify-content-end mb-2"
            >
              <b-button
                v-if="$route.query.client_hide == 'false'"
                variant="primary"
                class="mt-2 font-weight-normal font-medium-1"
                block
                @click="isAddNewBusinessUserSidebarActive = true"
              >
                Inscrire le client
              </b-button>
            </b-col>
          </b-row>
          <b-row
            v-for="(form, index) in forms"
            id="new-client-form"
            :key="index"
          >
            <!-- Search employee -->
            <!-- <b-col
              md="6"
              class="mb-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Recherche ou gestion d'employé"
                rules="required"
              >
                <b-form-radio-group
                  v-model="form.search_employee"
                  :options="typeOptions"
                  :state="errors.length > 0 ? false : null"
                  value-field="item"
                  text-field="name"
                  class="demo-inline-spacing custom-control-success"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col> -->

            <!-- Employee firstname -->
            <b-col
              v-if="$route.query.search_employee === 0"
              md="6"
            >
              <b-form-group
                label="Prénom de l'employé"
                label-for="firstname"
              >
                <validation-provider
                  #default="{ errors }"
                  name=" prenom & nom "
                  rules="required"
                >
                  <b-form-input
                    id="firstname"
                    v-model="form.employee_first_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez le Prénom l'employé"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Employee phone number -->
            <!-- <b-col
              v-if="form.search_employee === 0"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Numéro de téléphone"
                rules="required"
              >
                <b-form-group
                  label="Numéro de téléphone"
                  label-for="phone"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      is-text
                      :class="errors[0] ? 'border-danger' : ''"
                    >
                      BJ (+229)
                    </b-input-group-prepend>
                    <cleave
                      id="phone"
                      v-model="phone"
                      class="form-control"
                      :class="errors[0] ? 'border-danger' : ''"
                      :raw="false"
                      :options="options.phone"
                      placeholder="12 34 56 78"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col> -->

            <!-- Requested service -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Service recherché"
                rules="required"
              >
                <b-form-group
                  label="Service recherché"
                  label-for="services"
                >
                  <v-select
                    id="role"
                    v-model="form.recurring_service_id"
                    :reduce="(service) => service.id"
                    label="name"
                    :options="recurringServicesOption"
                    :state="errors.length > 0 ? false : null"
                  >
                    <template v-slot:no-options>
                      <b-spinner
                        v-if="isServicesRecurringWithoutPaginationLoading"
                        style="width: 2.5rem; height: 2.5rem"
                        class="align-middle text-info"
                      />

                      <span
                        v-else
                        class="mt-1 font-medium-1"
                      >Ce service n'existe pas</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Salaire net -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="budget par employé"
                rules="required|min_value:1"
              >
                <b-form-group
                  label="Budget par employé"
                  label-for="brut_salary"
                >
                  <b-form-input
                    id="brut_salary"
                    v-model="form.brut_salary"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez le budget pour un seul employé"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- CNSS -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Cnss"
                rules="required"
              >
                <b-form-group
                  label="Déclarer à la cnss"
                  label-for="cnss"
                >
                  <b-form-select
                    id="cnss"
                    v-model="form.cnss"
                    :options="cnssOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Nombre d'employés -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Nombre d'employé"
                rules="required|min_value:1"
              >
                <b-form-group
                  label="Nombre d'employé"
                  label-for="number_of_employees"
                >
                  <b-form-input
                    id="number_of_employees"
                    v-model="form.number_of_employees"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez le nombre d'employé"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Intervention frequency -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Fréquence d'intervention"
                rules="required"
              >
                <b-form-group
                  label="Fréquence d'intervention"
                  label-for="intervention_frequency"
                >
                  <b-form-select
                    id="intervention-frequency"
                    v-model="form.intervention_frequency"
                    :options="interventionFrequencyOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Address -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="lieu d'intervention"
                rules="required"
              >
                <b-form-group
                  label="Lieu d'intervention"
                  label-for="address"
                >
                  <b-form-input
                    id="address"
                    v-model="form.address"
                    placeholder="Lieu d'intervention"
                    :state="errors.length > 0 ? false : null"
                    autocomplete="off"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Intervention frequency -->
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                name="contract"
              >
                <b-form-group
                  label="Contrat de prestation"
                  label-for="contract"
                >
                  <b-form-file
                    v-model="form.contract"
                    accept=".pdf"
                    placeholder="Sélectionnez un fichier PDF"
                    :state="Boolean(pdfFile)"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- CNSS -->
            <!-- <b-col md="6" v-if="
              getUser.role.slug === 'responsable-relation-client' ||
              getUser.role.slug === 'super-administrateur'">
              <validation-provider #default="{ errors }" name="auto_assignment" rules="required">
                <b-form-group label="Me l'assigner" label-for="auto_assignment">
                  <b-form-select id="auto_assignment" v-model="form.auto_assignment" :options="autoAssignmentOptions"
                    :state="errors.length > 0 ? false : null" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col> -->
            <b-col
              cols="12"
              md="4"
              class="mb-4"
            >
              <b-button
                variant="danger"
                :disabled="forms.length == 1"
                @click="removeItem(index)"
              >
                Supprimer
              </b-button>
            </b-col>

          </b-row>
        </b-form>

        <b-row>
          <!-- Reapet form -->

          <b-col
            cols="12"
            md="8"
            class="mb-4 d-flex align-items-center justify-content-start mb-2"
          >
            <b-button
              variant="primary"
              @click="repeateAgain"
            >
              Ajouter un autre service
            </b-button>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-end mb-2"
            md="4"
            @click="applyAddNewOrder()"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="isAddNewOrderLoading"
            >
              {{ !isAddNewOrderLoading ? "Passer la commande" : "Chargement..." }}
            </b-button>
          </b-col>
        </b-row>
        <!-- submit and reset -->

      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BSpinner,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormFile,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, min } from '@validations'
import { mapGetters, mapActions } from 'vuex'
import Cleave from 'vue-cleave-component'
import NewBusinessClient from '@/views/order/business/NewBusinessClient.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.bj'

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormFile,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    NewBusinessClient,
    BBreadcrumb,
    BBreadcrumbItem,

    Cleave,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      cpt: 0,
      storeElement:
      {
        address: '',
        cnss: '',
      },
      isAddNewBusinessUserSidebarActive: false,

      clientData: null,

      isAddNewOrderLoading: false,

      isCustomersListLoading: true,

      required,
      min,

      recurringServicesOption: [],

      customerOptions: [],
      forms: [
        {
          recurring_service_id: '',
          brut_salary: '',
          intervention_frequency: '',
          address: '',
          number_of_employees: '',
          cnss: '',
          contract: '',

        },
      ],

      // typeOptions: [
      //   { item: 1, name: "À la recherche d'employé" },
      //   { item: 0, name: "Confier la gestion d'employé" },
      // ],

      interventionFrequencyOptions: [
        {
          value: 7,
          text: 'Tous les jours',
        },
        {
          value: 1,
          text: '1 fois par semaine',
        },
        {
          value: 2,
          text: '2 fois par semaine',
        },
        {
          value: 3,
          text: '3 fois par semaine',
        },
        {
          value: 4,
          text: '4 fois par semaine',
        },
        {
          value: 5,
          text: '5 fois par semaine',
        },
        {
          value: 6,
          text: '6 fois par semaine',
        },
      ],

      offerTypeOptions: [
        {
          value: 1,
          text: 'Confort',
        },
        {
          value: 2,
          text: 'Premium',
        },
      ],
      autoAssignmentOptions: [
        {
          value: 1,
          text: 'Oui',
        },
        {
          value: 0,
          text: 'Non',
        },
      ],

      cnssOptions: [
        {
          value: 1,
          text: 'Oui',
        },
        {
          value: 0,
          text: 'Non',
        },
      ],

      frequency: '',
      other_intervention_frequency: '',

      payload: {
        businessRecurringOrders: [],
        user_id: '',
      },

      phone: '',

      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'BJ',
        },
      },
    }
  },

  created() {
    console.log(this.getRecurringServicesWithoutPagination)
    console.log('storeElement', this.storeElement)
    console.log('Client', this.getCustomer)
    // this.applyGetRecurringServices()
    // this.isPackageClicked
  },

  computed: {
    ...mapGetters('services', [
      'getRecurringServices',
      'getRecurringServicesWithoutPagination',
    ]),

    ...mapGetters(['isRecurringServicesLoading', 'isCustomersWithoutPaginationLoading', 'isServicesRecurringWithoutPaginationLoading']),

    ...mapGetters('customers', ['getCustomers', 'getCustomersWithoutPagination', 'getCustomer'], 'isPackageClicked'),

    ...mapGetters('auth', ['getUser']),

    clientName() {
      return `${this.getCustomer.first_name} ${this.getCustomer.last_name}`
    },
  },

  watch: {

    isCustomersWithoutPaginationLoading(val) {
      if (val === false) {
        this.customerOptions = []
        this.getCustomersWithoutPagination.forEach(element => {
          this.customerOptions.push({
            id: element.id,
            name: element.customer_full_name,
          })
        })
      }
    },

    isServicesRecurringWithoutPaginationLoading(val) {
      if (val === false) {
        this.getRecurringServicesWithoutPagination.forEach(element => {
          this.recurringServicesOption.push({
            id: element.id,
            name: element.name,
          })
        })
      }
    },

  },

  mounted() {
    if (this.getRecurringServicesWithoutPagination.length != 0) {
      this.getRecurringServicesWithoutPagination.forEach(element => {
        this.recurringServicesOption.push({
          id: element.id,
          name: element.name,
        })
      })
    }

    if (this.getCustomersWithoutPagination.length != 0) {
      this.getCustomersWithoutPagination.forEach(element => {
        this.customerOptions.push({
          id: element.id,
          name: element.customer_full_name,
        })
      })
    }

    console.log('CUSTOMERS', this.customerOptions)
    this.configProAddressField()
  },

  methods: {
    ...mapActions('orders', ['makeBusinessOrderForAClientAction', 'getBusinessOrdersAction']),
    ...mapActions('packages', ['newPackageOrderAction']),
    ...mapActions('services', ['getRecurringServicesWithoutPaginationAction']),

    ...mapActions('customers', [
      'addNewCustomerAction',
      'getCustomersAction',
      'getCustomersWithoutPaginationAction',
    ]),
    ...mapActions('packages', ['getDirectPackagesAction']),

    applyGetRecurringServices() {
      this.getRecurringServicesWithoutPaginationAction()
        .then(response => {
          console.log('SERVICES', response)
          response.forEach(element => {
            this.recurringServicesOption.push({
              id: element.id,
              name: element.name,
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    // package liste action
    applyGetDirectPackagesAction() {
      this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', true)
      this.getDirectPackagesAction()
        .then(response => {
          this.clientPackage = response.data
          this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
          console.log(response)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
        })
    },

    configProAddressField() {
      const input = document.getElementById('address')
      const options = {
        types: ['establishment'],
        componentRestrictions: { country: ['BJ'] },
      }
      this.autocomplete = new google.maps.places.Autocomplete(input, options)

      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace()
        this.forms.forEach(element => {
          element.address = place.name
        })
      })
    },

    applyGetBusinessOrders() {
      this.isReLoadDataRecurringOrders = true
      this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', true)

      this.getBusinessOrdersAction()
        .then(response => {
          this.isReLoadDataRecurringOrders = false
          this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', false)
        })
        .catch(error => {
          this.isReLoadDataRecurringOrders = false
          this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', false)
        })
    },

    repeateAgain() {
      this.$refs.addNewOrder.validate().then(success => {
        if (success) {
          this.storeElement = {

            address: this.forms[this.cpt].address,

            cnss: this.forms[this.cpt].cnss,
          }

          this.forms.push({
            recurring_service_id: '',
            brut_salary: '',
            intervention_frequency: '',
            offer_type_id: '',
            address: this.storeElement.address,
            number_of_employees: '',
            cnss: this.storeElement.cnss,
            contract: '',
          })
          this.cpt += 1
        } else {
          reject()
        }
      })
    },
    removeItem(index) {
      this.forms.splice(index, 1)
      this.cpt -= 1
    },

    applyAddNewOrder(event) {
      this.$refs.addNewOrder.validate().then(success => {
        if (success) {
          this.isAddNewOrderLoading = true
          this.payload.businessRecurringOrders = this.forms
          /* this.payload.businessRecurringOrders.forEach(element =>{
           element.contract = event.target.files[0]
          })  */
          console.log(this.payload, 'PAYLOAD!!!!')
          /* this.payload.search_employee = this.$route.query.search_employee == "true"
            ? 1
            : 0;
             */
          console.log(this.getCustomer)
          if (this.getCustomer !== null && this.$route.query.client_hide == 'false') {
            this.addNewCustomerAction(this.getCustomer)
              .then(response => {
                console.log('RESPONSE DATA', response)
                this.$store.commit(
                  'SET_IS_CUSTOMERS_WITHOUT_PAGINATION_LOADING',
                  true,
                )

                this.getCustomersWithoutPaginationAction().then(() => {
                  this.$store.commit(
                    'SET_IS_CUSTOMERS_WITHOUT_PAGINATION_LOADING',
                    false,
                  )
                  this.getCustomersAction().then(() => {

                  })

                  this.payload.user_id = response.data.id
                  console.log('ID CLIENT', this.payload.user_id)
                  if (this.getUser.role.slug == 'super-administrateur') {
                    this.payload.auto_assignment
                  }

                  this.makeBusinessOrderForAClientAction(this.payload)
                    .then(response => {
                      console.log('NewOrder', response)

                      this.$toast(
                        {
                          component: ToastificationContent,
                          props: {
                            title: 'Succès',
                            icon: 'CheckIcon',
                            text: response.message,
                            variant: 'success',
                          },
                        },
                        {
                          position: 'top-center',
                        },
                      )

                      this.isAddNewOrderLoading = false
                      this.$store.commit('orders/SET_CURRENT_BUSINESS_PACKAGE', response.data)
                      localStorage.setItem('clientBusinessPackage', JSON.stringify(response.data))
                      console.log('PACKAGE COURRANT', response.data)
                      this.applyGetBusinessOrders()
                      this.$router.push({
                        path: `/packages/${response.data.id}/business/orders`,
                      })
                      /* this.applyGetBusinessOrders();
                      this.$router.push({
                      path: `/packages/${response.data.id}/business/orders`,
                    }); */
                      // this.$router.push({path: `/orders/business`});
                      // localStorage.removeItem('newCustomer')
                      this.$store.commit('customers/SET_CUSTOMER', null)
                      // this.$store.commit('clearClientData');
                    })
                    .catch(error => {
                      console.log(error)
                      this.$toast(
                        {
                          component: ToastificationContent,
                          props: {
                            title: 'Erreur',
                            icon: 'TriangleIcon',
                            text: error.response.data.message,
                            variant: 'danger',
                          },
                        },
                        {
                          position: 'top-center',
                        },
                      )
                    })
                })
              })
              .catch(error => {
                this.isAddNewOrderLoading = false
                console.log(error)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erreur',
                      icon: 'TriangleIcon',
                      text: error.response.data.message,
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
                this.isAddNewBusinessUserSidebarActive = true
              })
          } else {
            if (this.$route.query.client_hide == 'false') {
              this.payload.user_id = this.payload.user_id
            } else {
              this.payload.user_id = JSON.parse(localStorage.getItem('clientBusinessPackage')).user.id
            }
            /* this.payload.user_id = this.forms.customer_id; */
            console.log('storeElement', this.storeElement)

            this.makeBusinessOrderForAClientAction(this.payload)

              .then(response => {
                console.log('NewOrder', response)

                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: response.message,
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )

                this.$store.commit('orders/SET_CURRENT_BUSINESS_PACKAGE', response.data)
                localStorage.setItem('clientBusinessPackage', JSON.stringify(response.data))
                console.log('PACKAGE COURRANT', response.data)
                this.applyGetBusinessOrders()
                this.$router.push({
                  path: `/packages/${response.data.id}/business/orders`,
                })
              })
              .catch(error => {
                console.log('NEW ORDER', error)
                /* this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Erreur",
                      icon: "TriangleIcon",
                      text: error.response.data.message,
                      variant: "danger",
                    },
                  },
                  {
                    position: "top-center",
                  }
                ); */
                this.isAddNewOrderLoading = false
                // localStorage.removeItem('newCustomer')
              })
          }
        }
      })
    },
  },
}
</script>
