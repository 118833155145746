<template>
<b-sidebar id="add-new-business-user-sidebar" :visible="isAddNewBusinessUserSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop no-close-on-backdrop no-close-on-esc no-header right @change="(val) => $emit('update:is-add-new-business-user-sidebar-active', val)">
    <template #default="{ hide }">
        <!-- Header -->
        <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
            <h5 class="mb-0">
                {{ accountActivationState ? 'Activer le compte du client' : 'Inscription client' }}
            </h5>

            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>

        <validation-observer #default="{}" ref="addNewCustomer">
            <!-- Form -->
            <b-form class="p-2" @submit.prevent="applyAddNewCustomerAction()" @reset.prevent="resetForm">
                <!-- Type de compte -->
                <validation-provider #default="{ errors }" name="compteType" rules="required">
                    <b-form-group label="Type du compte" label-for="compteType">
                        <b-form-select id="compteType" v-model="userData.is_company" :options="compteOptions" :state="errors.length > 0 ? false : null" />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                
                <!-- Entreprise Name -->
                <validation-provider #default="{ errors }" name="nomEntreprise" rules="required" v-if="userData.is_company == 1">
                    <b-form-group label="Nom de l'entreprise " label-for="nomEntreprise">
                        <b-form-input id="nomEntreprise" v-model="userData.company_name" autofocus :state="errors.length > 0 ? false : null" trim />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                </validation-provider>

                <!-- First Name -->
                <validation-provider #default="{ errors }" name="Nom et Prénom" rules="required" >
                    <b-form-group :label="userData.is_company == 1 ?'Nom et Prénom du dirigeant' : 'Nom et Prénom'  " label-for="name">
                        <b-form-input id="name" v-model="fullName" autofocus :state="errors.length > 0 ? false : null" trim />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                </validation-provider>

                <!-- Email -->
                <validation-provider #default="{ errors }" name="Email" rules="email">
                    <b-form-group label="Adresse mail" label-for="email">
                        <b-form-input id="email" v-model="userData.email" type="email" :state="errors.length > 0 ? false : null" trim />

                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                </validation-provider>

                <!-- Numero IFU -->
                <validation-provider #default="{ errors }" name="ifu" rules="length:13">
                    <b-form-group label="Numéro IFU" label-for="ifu">
                        <b-form-input id="ifu" v-model="userData.company_ifu" type="number" :state="errors.length > 0 ? false : null" trim />

                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                </validation-provider>
                <!--Company  Address -->
                <validation-provider #default="{ errors }" name="siège de l'entreprise" rules="required" v-if="userData.is_company == 1">
                    <b-form-group label="Siège de l'entreprise" label-for="company_address">
                        <b-form-input id="company_address" v-model="userData.company_address" placeholder="Siège de l'entreprise" :state="errors.length > 0 ? false : null" autocomplete="off" />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <!-- Phone Number -->
                <validation-provider #default="{ errors }" name="Numéro de téléphone" rules="required">
                    <b-form-group label="Numéro de téléphone" label-for="phone">
                        <b-input-group>
                            <b-input-group-prepend is-text :class="errors[0] ? 'border-danger' : ''">
                                BJ (+229)
                            </b-input-group-prepend>
                            <cleave id="phone" v-model="phone" class="form-control" :class="errors[0] ? 'border-danger' : ''" :raw="false" :options="options.phone" placeholder="12 34 56 78" />
                        </b-input-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                </validation-provider>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" :disabled="isAddNewCustomerLoading" class="mr-2" type="submit">
                        {{ !isAddNewCustomerLoading ? "Soumettre" : "Chargement..." }}
                    </b-button>
                    <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Retour
            </b-button> -->
                </div>
            </b-form>
        </validation-observer>
    </template>
</b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BInputGroupPrepend,
    BButton,
} from 'bootstrap-vue'
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    required,
    email
} from '@validations'
import Ripple from 'vue-ripple-directive'
import {
    mapActions
} from 'vuex'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.bj'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormSelect,
        BButton,
        ValidationProvider,
        ValidationObserver,
        Cleave,
    },

    directives: {
        Ripple,
    },

    model: {
        prop: 'isAddNewBusinessUserSidebarActive',
        event: 'update:is-add-new-business-user-sidebar-active',
    },

    props: {
        isAddNewBusinessUserSidebarActive: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            fullName: '',
            required,
            isAddNewCustomerLoading: false,
            email,
            userData: {
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                is_company : 1,
                account_type : "business",
            },
            phone: '',

            compteOptions: [{
                    value: 1,
                    text: 'Compte Entreprise',
                },
                {
                    value: 0,
                    text: 'Compte Personnel',
                }
            ],
            options: {
                phone: {
                    phone: true,
                    phoneRegionCode: 'BJ',
                },
            },
            activationData: {
                activation_code: '',
            },
        }
    },

    computed: {
        phoneNumber() {
            return this.phone.replace(/\s/g, '')
        },
    },
    mounted() {
        this.configProAddressField()
    },

    methods: {
        ...mapActions('customers', ['addNewCustomerAction', 'getCustomersAction', 'getCustomersWithoutPaginationAction']),

        configProAddressField() {
            const input = document.getElementById('company_address')
            // alert(input)
            const options = {
                types: ['establishment'],
                componentRestrictions: {
                    country: ['BJ']
                },
            }
            this.autocomplete = new google.maps.places.Autocomplete(input, options)

            this.autocomplete.addListener('place_changed', () => {
                const place = this.autocomplete.getPlace()
                this.userData.company_address = place.name
            })
        },

        applyAddNewCustomerAction() {
            this.$refs.addNewCustomer.validate()
                .then(success => {
                    if (success) {
                        this.isAddNewCustomerLoading = true
                        this.userData.phone_number = (`229${this.phoneNumber}`)
                        const full_name = this.fullName.split(' ')
                        console.log(full_name)
                
                        if (full_name.length === 2) {
                            console.log(full_name.length)
                            this.userData.first_name = full_name[0];
                            this.userData.last_name = full_name[1]
                            console.log(this.userData)
                            this.$store.commit("customers/SET_CUSTOMER", this.userData);
                            this.isAddNewCustomerLoading = false
                            this.$emit('update:is-add-new-business-user-sidebar-active', false)
                            // this.addNewCustomerAction(this.userData)
                            // .then(response => {
                            //   console.log('RESPONSE DATA',response)
                            //   this.$store.commit(
                            //     'SET_IS_CUSTOMERS_WITHOUT_PAGINATION_LOADING',
                            //     true,
                            //   )
                            //   this.getCustomersWithoutPaginationAction().then(() => {
                            //     this.$store.commit(
                            //       'SET_IS_CUSTOMERS_WITHOUT_PAGINATION_LOADING',
                            //       false,
                            //     )
                            //     this.getCustomersAction().then(() => {
                            //       this.$toast(
                            //         {
                            //           component: ToastificationContent,
                            //           props: {
                            //             title: 'Succès',
                            //             icon: 'CheckIcon',
                            //             text: response.message,
                            //             variant: 'success',
                            //           },
                            //         },
                            //         {
                            //           position: 'top-center',
                            //         },
                            //       )
                            //       this.isAddNewCustomerLoading = false
                            //       this.$emit('update:is-add-new-user-sidebar-active', false)

                            //     })
                            //   })
                            // })
                            // .catch(error => {
                            //   this.userData.phone_number = ''
                            //   this.$toast(
                            //     {
                            //       component: ToastificationContent,
                            //       props: {
                            //         title: 'Erreur',
                            //         icon: 'TriangleIcon',
                            //         text: error.response.data.message,
                            //         variant: 'danger',
                            //       },
                            //     },
                            //     {
                            //       position: 'top-center',
                            //     },
                            //   )
                            //   this.isAddNewCustomerLoading = false
                            // })
                        } else {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Erreur',
                                    icon: 'TriangleIcon',
                                    text: "Vous devez séparer le nom et le prénom d'un espace",
                                    variant: 'danger',
                                },
                            }, {
                                position: 'top-center',
                            }, )
                            this.isAddNewCustomerLoading = false

                        }

                    }
                })
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-business-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
